import { useSelector } from "react-redux";
import { Button, Checkbox, Input } from "../../controls";
import { useLogin } from "./LoginContainer";
import { ChooseLanguage } from "../../common";
// comment
const LoginView = () => {
  const {
    fields,
    errors,
    onChange,
    loading,
    onSubmit,
    onCheckChnage,
    // onForgot,
  } = useLogin();

  const { languagePreference } = useSelector((state) => ({
    languagePreference: state.language.languagePreference,
  }));

  return (
    <div className="h-full auth flex-center flex-col">
      <div className="title-regular flex items-center">
        {languagePreference.sign_in}{" "}
        <div className="mt-3 ml-3">
          <ChooseLanguage />
        </div>
      </div>
      <div className="w-[300px] my-0 mx-auto">
        <Input
          type="email"
          value={fields["email"]}
          name={"email"}
          label={"username"}
          placeholder={languagePreference.username}
          onChange={onChange("email")}
          error={errors["email"]}
          disabled={loading}
        />
        <Input
          type="password"
          value={fields["password"]}
          name={"password"}
          label={"password"}
          placeholder={languagePreference.password}
          onChange={onChange("password")}
          error={errors["password"]}
          disabled={loading}
        />

        <div className="mb-4 relative top-[-6px]">
          <Checkbox
            label={languagePreference.remember_me}
            checked={fields["remember"]}
            name={"remember"}
            onChange={onCheckChnage("remember")}
          />
        </div>

        <Button
          size="full"
          title={languagePreference.login}
          loading={loading}
          disabled={loading}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default LoginView;
