export const actionTypes = {
  SHOW_ALERT: "SHOW_ALERT",
  HIDE_ALERT: "HIDE_ALERT",
};

export function hideAlert() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.HIDE_ALERT,
      payload: {
        title: null,
        isOpen: false,
        msg: null,
      },
    });
    return;
  };
}

export function showAlert(title, message) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SHOW_ALERT,
      payload: {
        title: "show",
        isOpen: true,
        msg: "show",
      },
    });
    return;
  };
}

export function showLoader() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SHOW_ALERT,
      title: null,
      isOpen: true,
      msg: null,
      alertType: "loading",
    });
  };
}
