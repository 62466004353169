import { actionTypes } from "./movieActions";

const {
  MOVIE_LIST_PENDING,
  MOVIE_LIST_SUCCESS,
  MOVIE_LIST_ERROR,
  MOVIE_CREATE_PENDING,
  MOVIE_CREATE_SUCCESS,
  MOVIE_CREATE_ERROR,
  MOVIE_UPDATE_PENDING,
  MOVIE_UPDATE_SUCCESS,
  MOVIE_UPDATE_ERROR,
  MOVIE_DELETE_PENDING,
  MOVIE_DELETE_SUCCESS,
  MOVIE_DELETE_ERROR,
  MOVIE_PENDING,
  MOVIE_SUCCESS,
  MOVIE_ERROR,
} = actionTypes;

const initialState = {
  movieListPending: false,
  movieListSuccess: false,
  movieListError: null,
  movieListResponse: null,

  movieCreatePending: false,
  movieCreateSuccess: false,
  movieCreateError: null,
  movieCreateResponse: null,

  movieUpdatePending: false,
  movieUpdateSuccess: false,
  movieUpdateError: null,
  movieUpdateResponse: null,

  moviePending: false,
  movieSuccess: false,
  movieError: null,
  movieResponse: null,

  movieDeletePending: false,
  movieDeleteSuccess: false,
  movieDeleteError: null,
  movieDeleteResponse: null,
};

export default function movie(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case MOVIE_LIST_PENDING:
    case MOVIE_LIST_SUCCESS:
    case MOVIE_LIST_ERROR:
      return Object.assign({}, state, payload);
    case MOVIE_CREATE_PENDING:
    case MOVIE_CREATE_SUCCESS:
    case MOVIE_CREATE_ERROR:
      return Object.assign({}, state, payload);
    case MOVIE_UPDATE_PENDING:
    case MOVIE_UPDATE_SUCCESS:
    case MOVIE_UPDATE_ERROR:
      return Object.assign({}, state, payload);
    case MOVIE_DELETE_PENDING:
    case MOVIE_DELETE_SUCCESS:
    case MOVIE_DELETE_ERROR:
      return Object.assign({}, state, payload);
    case MOVIE_PENDING:
    case MOVIE_SUCCESS:
    case MOVIE_ERROR:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
