export default class Auth {
  token = null;
  static getTokenFromLocalStorage() {
    try {
      return window.localStorage.getItem("token")
        ? JSON.parse(window.localStorage.getItem("token"))
        : null;
    } catch (e) {
      localStorage.clear();
    }
  }
  static getLoginUserFromLocalStorage() {
    try {
      return window.localStorage.getItem("userData")
        ? JSON.parse(window.localStorage.getItem("userData"))
        : null;
    } catch (e) {
      localStorage.clear();
    }
  }
}
