import React, {
  useEffect,
  createContext,
  useContext,
  useState,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import LoginView from "./LoginView";
import * as validator from "../../../helpers/validator";
import { trimFieldsData } from "../../../utils";
import { login } from "../../../store/login/loginActions";
import { decrypt_pwd } from "../../../services/pwdProtect";
import auth from "../../common/ProtectedRoute/auth";

const LoginContext = createContext({});
export const useLogin = () => useContext(LoginContext);

const initFields = {
  email: "",
  password: "",
  remember: false,
};

const LoginContainer = () => {
  const dispatch = useDispatch();
  const { loginPending } = useSelector((state) => ({
    loginPending: state.login.loginPending,
  }));

  const [fields, setFields] = useState(initFields);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const loginRemember = localStorage.getItem("loginRemember")
      ? JSON.parse(localStorage.getItem("loginRemember"))
      : null;
    if (loginRemember) {
      fillLoginFields(loginRemember);
    }
  }, []);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter") {
        handleSubmit();
      }
    },
    [fields]
  );

  useEffect(() => {
    window.addEventListener("keypress", handleKeyDown);
    return () => {
      window.removeEventListener("keypress", handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleChange = (field) => (e) => {
    let value;
    const isDate = e instanceof Date;
    const isTextField = e?.target;
    const isNull = e === null;
    if (isDate) {
      value = e;
    } else if (isNull) {
      value = e;
    } else if (isTextField) {
      value = e.target.value;
    } else {
      value = e;
    }

    setFields({
      ...fields,
      [field]: value,
    });
  };

  const handleCheckChange = (field) => (e) => {
    var value = fields.remember;

    setFields({
      ...fields,
      [field]: !value,
    });
  };

  const handleForgot = () => {
    // forget functionality here
  };

  const handleSubmit = () => {
    var custom = [validator.email(["email"])];
    const validatorFields = {
      required: ["email", "password"],
      custom: custom,
    };

    const { errors } = validator.validate(
      validatorFields,
      trimFieldsData(fields)
    );

    setErrors(errors);

    if (!Object.keys(errors).length) {
      // console.log({ fields });
      dispatch(login(fields));
    }
  };

  const fillLoginFields = (loginRemember) => {
    const { email, password } = loginRemember;

    var pwd = decrypt_pwd(password);

    setFields({
      email: email,
      password: pwd,
      remember: true,
    });
  };

  return auth.isAuthenticated() ? (
    <Navigate to="/" replace />
  ) : (
    <LoginContext.Provider
      value={{
        fields,
        errors,
        onChange: handleChange,
        loading: loginPending,
        onSubmit: handleSubmit,
        onCheckChnage: handleCheckChange,
        onForgot: handleForgot,
      }}
    >
      <LoginView />
    </LoginContext.Provider>
  );
};

export default LoginContainer;
