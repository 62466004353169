import React from "react";
import { useNavigate } from "react-router-dom";

import { FileUploader, Header } from "../../../common";
import { Button, Input } from "../../../controls";
import { useCreateMovie } from "./MoviesCreateContainer";
import { useSelector } from "react-redux";

const MoviesCreateView = () => {
  let navigate = useNavigate();

  const { languagePreference } = useSelector((state) => ({
    languagePreference: state.language.languagePreference,
  }));

  const { fields, errors, imageUrl, setImageUrl, onChange, loading, onSubmit } =
    useCreateMovie();

  return (
    <div className="create h-full text-white">
      <Header
        title={languagePreference.create_new_movie}
        showBack
        showLogout={false}
      />

      <div className="create-form flex flex-col-reverse lg:flex-row lg:flex-wrap">
        <div className="lg:flex-[0_0_40%] p-[10px]">
          <div
            className={`${
              errors["file"] ? "border-errorColor" : "border-white"
            } bg-secondary  rounded-lg border-dashed border-2 mx-auto my-0 relative`}
          >
            <FileUploader
              setFile={onChange("file")}
              setImageUrl={setImageUrl}
              imageUrl={imageUrl}
              file={fields.file}
              error={errors["file"]}
            />
            <span className="absolute bottom-[-25px] left-[5px] text-errorColor text-sm not-italic font-normal leading-4">
              {errors["file"]}
            </span>
          </div>
          <div className="flex md:hidden btn-grp gap-4 mt-10">
            <Button
              size="md"
              title={languagePreference.cancel}
              styleName="secondary"
              onClick={() => {
                navigate(-1);
              }}
              disabled={loading}
            />
            <Button
              size="md"
              title={languagePreference.submit}
              loading={loading}
              disabled={loading}
              onClick={onSubmit}
            />
          </div>
        </div>
        <div className="lg:flex-[0_0_60%] p-[10px] lg:pl-20">
          <div className="form-grp flex flex-col">
            <Input
              type="text"
              placeholder={languagePreference.title}
              size="xl"
              focus
              value={fields["title"]}
              name={"title"}
              onChange={onChange("title")}
              error={errors["title"]}
              disabled={loading}
            />
            <Input
              type="tel"
              placeholder={languagePreference.publishing_year}
              size="lg"
              value={fields["year"]}
              name={"year"}
              onChange={onChange("year")}
              error={errors["year"]}
              disabled={loading}
              isvalnum="isNum"
              maxLength={4}
            />
          </div>
          <div className="hidden md:flex btn-grp gap-4 mt-3">
            <Button
              size="md"
              title={languagePreference.cancel}
              styleName="secondary"
              onClick={() => {
                navigate(-1);
              }}
              disabled={loading}
            />
            <Button
              size="md"
              title={languagePreference.submit}
              loading={loading}
              disabled={loading}
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoviesCreateView;
