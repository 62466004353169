import React, {
  useEffect,
  createContext,
  useContext,
  useState,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import MoviesCreateView from "./MoviesCreateView";
import { createMovie } from "../../../../store/movie/movieActions";

const CreateMovieContext = createContext({});
export const useCreateMovie = () => useContext(CreateMovieContext);

const initFields = {
  title: "",
  year: "",
  file: null,
};

const MoviesCreateContainer = () => {
  const dispatch = useDispatch();
  const { movieCreatePending } = useSelector((state) => ({
    movieCreatePending: state.movie.movieCreatePending,
  }));

  const [fields, setFields] = useState(initFields);
  const [errors, setErrors] = useState({});
  const [imageUrl, setImageUrl] = useState("");

  const handleChange = (field) => (e) => {
    let value;
    const isDate = e instanceof Date;
    const isTextField = e?.target;
    const isNull = e === null;
    if (isDate) {
      value = e;
    } else if (isNull) {
      value = e;
    } else if (isTextField) {
      value = e.target.value;
    } else {
      value = e;
    }

    setFields({
      ...fields,
      [field]: value,
    });
  };

  const handleSubmit = () => {
    const validatorFields = {
      required: ["title", "year", "file"],
    };

    const { errors } = validator.validate(
      validatorFields,
      trimFieldsData(fields)
    );

    // console.log({ errors });

    setErrors(errors);

    if (!Object.keys(errors).length) {
      dispatch(createMovie(fields));
    }
  };

  // console.log({ movieCreatePending });

  return (
    <CreateMovieContext.Provider
      value={{
        fields,
        errors,
        imageUrl,
        setImageUrl,

        onChange: handleChange,
        loading: movieCreatePending,
        onSubmit: handleSubmit,
      }}
    >
      <MoviesCreateView />
    </CreateMovieContext.Provider>
  );
};

export default MoviesCreateContainer;
