import { Outlet, useLocation } from "react-router-dom";

import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import Wave from "../Wave/Wave";
import { LOGIN, SIGNUP } from "../../../navigation/routes";

const noAuthRequired = [LOGIN, SIGNUP];

const Layout = () => {
  const location = useLocation();

  return (
    <>
      {noAuthRequired.indexOf(location.pathname) !== -1 ? (
        <div className="flex h-screen flex-col">
          <div className="flex-1 py-16">
            <main className="container h-full">
              <Outlet />
            </main>
          </div>
          <Wave />
        </div>
      ) : (
        <ProtectedRoute location={location}>
          <div className="flex h-screen flex-col">
            <div className="flex-1 py-16">
              <main className="container h-full">
                <Outlet />
              </main>
            </div>
            <Wave />
          </div>
        </ProtectedRoute>
      )}
    </>
  );
};

export default Layout;
