// phone validation of user
export function isValidPhoneNumber(phone) {
  if (
    !phone == "" &&
    !/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/.test(phone)
  )
    return true;
  else return false;
}

export function isNonEmptyString(value) {
  var val = true;
  if (value) {
    if (value.length == 0) {
      val = false;
    }
  } else {
    val = false;
  }
  return !val;
}

// check for empty array
export function isNonEmptyArray(arr) {
  if (arr) {
    if (arr.length == 0) return false;
    else return true;
  }
  return false;
}

// zipcode validation of organization
export function isValidZipcode(pincode) {
  if (!pincode == "" && !/^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(pincode))
    return true;
  else return false;
}

// password validation of user
export function isValidPassword(password) {
  if (password && password.length > 0 && password.length < 8) return true;
  else return false;
}

// number validation of grant(amount)
export function isValidNumber(num) {
  if (num != null && !/[0-9]/.test(num)) return true;
  else return false;
}

// send error for action file
export function sendError(error) {
  var message = "";
  if (typeof error == "object") {
    message = error["data"].message;
  } else if (typeof error == "string") {
    message = error;
  } else {
    message = "";
  }
  return message;
}

// validation for alphanumeric and space
export function isValidAlphanumeric(val) {
  if (val != null && /^[A-Za-z0-9? ]+$/.test(val)) {
    return true;
  } else return false;
}

export function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function sortByKey(array, key) {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

export function isObjEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}
