import React from "react";
import ReactDOM from "react-dom/client";
import { ReactNotifications } from "react-notifications-component";
import { unstable_HistoryRouter as HistoryRoute } from "react-router-dom";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import { store, history } from "./store/configure/configureStore";
import "react-notifications-component/dist/theme.css";
import "./assets/styles/index.css";
// import { Alert } from "./components/common";

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <HistoryRoute history={history}>
        <ReactNotifications />
        <App />
        {/* <Alert /> */}
      </HistoryRoute>
    </PersistGate>
  </Provider>
);
