import { languageList } from "../../constants/localizations";

export const actionTypes = {
  SET_LANGUAGE_PREFERENCE: "SET_LANGUAGE_PREFERENCE",
};

export function setLanguagePreference(val) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_LANGUAGE_PREFERENCE,
      payload: {
        languagePreference: languageList[val],
      },
    });

    localStorage.setItem("chosenLanguageKey", JSON.stringify(val));
  };
}
