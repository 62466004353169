import { globals } from "../../../store/globals";

class Auth {
  constructor() {
    this.authenticated = true;
  }

  isAuthenticated() {
    if (!globals.store.getState().login.accessToken) {
      this.authenticated = false;
    } else {
      this.authenticated = true;
    }

    return this.authenticated;
  }
}

export default new Auth();
