import React from "react";

const isNumber = (type) => type === "number";

const getSize = (size) => {
  switch (size) {
    case "md":
      return "w-full md:w-[150px]";
    case "lg":
      return "w-full md:w-[250px]";
    case "xl":
      return "w-full md:w-[350px]";
    default:
      return "w-full md:w-full";
  }
};

const Input = (props) => {
  const {
    type,
    error,
    size = "",
    containerSize,
    styleprop,
    isvalnum = "",
    onChange,
    value,
    placeholder,
    focus = false,
  } = props;

  const handleKeyPress = (e) => {
    if (isvalnum === "isNum") {
      let charCode = e.which ? e.which : e.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
      }
    }
  };
  return (
    <div className={`${getSize(size)} relative`}>
      <input
        value={value}
        placeholder={placeholder}
        type={type}
        style={isNumber(type) ? { padding: "0 15px" } : {}}
        className={`h-[45px] ${getSize(
          size
        )} mb-8 rounded-[10px] bg-secondary px-4 py-3 placeholder:text-white-10 outline-none ${
          error && "border-errorColor border-2"
        }`}
        onKeyPress={handleKeyPress}
        onChange={onChange}
        autoFocus={focus}
        {...props}
      />

      <span className="absolute bottom-[10px] left-[5px] text-errorColor text-sm not-italic font-normal leading-4">
        {error}
      </span>
    </div>
  );
};

export default Input;
