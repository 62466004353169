import { globals } from "../globals";
import { rootReducer } from "./rootReducer";
import { applyMiddleware, createStore } from "redux";
import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";
import thunk from "redux-thunk";

const { routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
  //other options if needed
});

export const store = createStore(
  rootReducer(routerReducer),
  {},
  applyMiddleware(thunk)
);
export const history = createBrowserHistory();

globals.history = history;

globals.store = store;
