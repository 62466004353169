import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import MoviesListView from "./MoviesListView";
import { getMoviesList } from "../../../../store/movie/movieActions";

const MoviesListContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMoviesList());
  }, []);

  const handleChangePage = (page) => {
    dispatch(getMoviesList(page));
  };

  return <MoviesListView onChangePage={handleChangePage} />;
};

export default MoviesListContainer;
