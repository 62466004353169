import React, { useState } from "react";
import { X } from "lucide-react";
import Compressor from "compressorjs";

import { uploadIcon } from "../../../constants/imageConstant";
import { Button } from "../../controls";
import { convertFileToUrl } from "../../../utils";
import { useSelector } from "react-redux";

const FileUploader = ({ imageUrl, setImageUrl, setFile, file }) => {
  const [isDragging, setIsDragging] = useState(false);

  const { languagePreference } = useSelector((state) => ({
    languagePreference: state.language.languagePreference,
  }));

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];

    new Compressor(file, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        setFile(compressedResult);
        setImageUrl(convertFileToUrl(file));
      },
    });
  };

  const handleButtonClick = () => {
    document.getElementById("input-file-upload").click();
  };

  return (
    <div className="h-[400px] flex items-center justify-center w-full">
      {imageUrl ? (
        <div className="relative flex h-[300px] w-full flex-1 justify-center my-10 mx-16 bg-white flex-center">
          <X
            color="#fff"
            className="absolute top-[-10px] right-[-10px] bg-black p-1 rounded-[50%] cursor-pointer"
            onClick={() => {
              setImageUrl("");
              setFile(null);
            }}
          />
          <img
            // src={imageUrl}
            src={`${imageUrl}`}
            // src={`${imageUrl}?${Math.random()}`}
            alt="image"
            className="h-[250px] w-auto object-cover object-center"
          />
          <div className="absolute bottom-[-35px]">{file?.name}</div>
        </div>
      ) : (
        <label
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          htmlFor="dropzone-file"
          className="flex flex-col items-center justify-center w-full h-[450px] rounded-lg cursor-pointer"
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <img
              src={uploadIcon}
              width={30}
              height={30}
              alt="file upload"
              className="mb-2"
            />
            {/* <p className="p-medium-12 mb-4">SVG, PNG, JPG</p> */}
            <Button type="button" className="rounded-full">
              {languagePreference.drop_image_here}
            </Button>
          </div>
          <input
            id="dropzone-file"
            type="file"
            className="hidden"
            accept="image/*"
            onChange={(e) => {
              // console.log(e.target.files[0]);

              setFile(e.target.files[0]);
              setImageUrl(convertFileToUrl(e.target.files[0]));

              // new Compressor(e.target.files[0], {
              //   quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
              //   success: (compressedResult) => {
              //     // compressedResult has the compressed file.
              //     // Use the compressed file to upload the images to your server.
              //     setFile(compressedResult);
              //     setImageUrl(convertFileToUrl(e.target.files[0]));
              //   },
              // });
            }}
          />
        </label>
      )}
    </div>
  );
};

export default FileUploader;
