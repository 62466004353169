import { Pencil, Trash } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { MotionDiv } from "./Motion";
import { CREATE, LIST } from "../../../navigation/routes";

const stagger = 0.25;

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

function MovieCard({ data, index, setOpen, setData }) {
  let navigate = useNavigate();

  return (
    <MotionDiv
      variants={variants}
      initial="hidden"
      animate="visible"
      transition={{
        delay: index * stagger,
        ease: "easeInOut",
        duration: 0.5,
      }}
      viewport={{ amount: 0 }}
      className="max-w-sm rounded-[12px] relative w-full bg-paginationBg px-2 py-3 md:p-2  mx-auto my-0"
    >
      <div className="relative w-full h-[450px] md:h-[350px] 2xl:h-[400px] overflow-hidden rounded-xl flex-center bg-paginationBg">
        <img
          onClick={() => {
            navigate(`${LIST}/update/${data._id}`);
          }}
          src={`${data.imageUrl}?${Math.random()}`}
          alt={data.title}
          className="rounded-xl w-full h-auto hover:scale-105 duration-300 transition ease-in-out cursor-pointer"
        />
        <div className="action-card absolute top-0 right-0 py-2 px-3 bg-black-rgba rounded-xl ">
          <div
            onClick={() => {
              navigate(`${LIST}/update/${data._id}`);
            }}
            className="edit mb-4 lg:mb-2 cursor-pointer"
          >
            <Pencil
              className="h[30px] w-[30px] lg:h-[20px] lg:w-[20px]"
              color="#2bd17e"
            />
          </div>
          <div
            onClick={() => {
              setOpen(true);
              setData(data);
            }}
            className="delete cursor-pointer"
          >
            <Trash
              className="h[30px] w-[30px] lg:h-[20px] lg:w-[20px]"
              color="#FF7F7F"
            />
          </div>
        </div>
      </div>
      <div className="pt-4 flex flex-col gap-3 pl-2">
        <div className="flex justify-between items-center gap-1">
          <h2 className="font-bold text-white text-xl line-clamp-1 w-full">
            {data.title}
          </h2>
        </div>
        <div className="flex gap-4 items-center">
          <div className="flex flex-row gap-2 items-center">
            <p className="text-base text-white font-bold">{data.year}</p>
          </div>
        </div>
      </div>
    </MotionDiv>
  );
}

export default MovieCard;
