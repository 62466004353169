import { Search, PlusCircle } from "lucide-react";
import BeatLoader from "react-spinners/BeatLoader";

const getClassName = (styleName) => {
  switch (styleName) {
    case "secondary":
      return "bg-transparent border-white border-[1px]";
    default:
      return "bg-primary";
  }
};

const getSize = (size) => {
  switch (size) {
    case "full":
      return "w-full";
    case "md":
      return "w-full md:w-[150px]";
    case "lg":
      return "w-full md:w-[250px]";
    default:
      return "";
  }
};

const Button = ({
  title,
  onClick,
  styleName = "",
  loading,
  disabled,
  transparent,
  shadow,
  size = "",
  type,
  accordion,
  isSubmitting = false,
  children,
}) => {
  return children ? (
    <>{children}</>
  ) : (
    <button
      onClick={onClick}
      className={`${"py-[15px] px-[28px] rounded-[10px] text-white "} ${
        type === "search" && "normal"
      } ${getSize(size)} ${getClassName(styleName)}
      ${disabled && "disabled"}
      ${transparent && "transparent"}
      ${shadow && "shadow"}`}
      disabled={disabled || loading}
    >
      {type === "search" && <Search />}
      {type === "userAdd" && <PlusCircle />}
      <span>
        {loading ? (
          <BeatLoader
            className="inline-block relative top-1"
            color={"#ffffff"}
          />
        ) : (
          title
        )}
      </span>
    </button>
  );
};

export default Button;
