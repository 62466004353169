import { Routes, Route, Navigate } from "react-router-dom";

import MoviesListContainer from "./MoviesList/MoviesListContainer";
import { CREATE, LIST, UPDATE } from "../../../navigation/routes";
import MoviesCreateContainer from "./MoviesCreate/MoviesCreateContainer";
import MoviesUpdateContainer from "./MoviesUpdate/MoviesUpdateContainer";

function Movies() {
  return (
    <Routes>
      <Route index element={<MoviesListContainer />} />
      <Route path={CREATE} element={<MoviesCreateContainer />} />
      <Route path={UPDATE} element={<MoviesUpdateContainer />} />
      <Route path="*" element={<Navigate to={LIST} replace />} />
    </Routes>
  );
}

export default Movies;
