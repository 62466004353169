export const convertFileToUrl = (file) => URL.createObjectURL(file);

export const trimField = (field) =>
  typeof field === "string" ? field.trim() : field;

export const trimFieldsData = (data) => {
  const newData = {};
  for (let field in data) {
    if (typeof data[field] === "string") {
      newData[field] = data[field].trim();
    } else {
      // console.log(newData[field]);
      newData[field] = data[field];
    }
  }
  return newData;
};
