import { Routes, Route, Navigate } from "react-router-dom";

import { Layout } from "./components/common";
import { HOME, LIST, LOGIN } from "./navigation/routes";
import { Home, Login } from "./components/pages";
import Movies from "./components/pages/movies";
import { useEffect } from "react";

function App() {
  const getLanguage =
    JSON.parse(localStorage.getItem("chosenLanguageKey")) || "en";

  useEffect(() => {
    localStorage.setItem("chosenLanguageKey", JSON.stringify(getLanguage));
  }, []);
  return (
    <Routes>
      <Route path={HOME} element={<Layout />}>
        <Route
          index
          // element={<Home />}
          element={<Navigate to={LIST} replace />}
        />
        <Route path={LOGIN} element={<Login />} />
        <Route path={`${LIST}/*`} element={<Movies />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
}

export default App;
