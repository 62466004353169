const Checkbox = (props) => {
  const { onChange, value, label } = props;

  return (
    <div className="flex-center">
      <input
        type="checkbox"
        value={value}
        className={`border-secondary rounded-[5px] mr-2 w-4 h-4 outline-none accent-secondary focus:accent-secondary hover:bg-white`}
        onChange={onChange}
        {...props}
      />
      <label className="not-italic font-extralight text-[16px] text-white">
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
