export const languageList = {
  en: {
    sign_in: "Sign in",
    username: "Username",
    password: "Password",
    remember_me: "Remember me",
    login: "Login",
    my_movies: "My movies",
    logout: "Logout",
    update_movie: "Update movie",
    cancel: "Cancel",
    update: "Update",
    create_new_movie: "Create a new movie",
    drop_image_here: "Drop an image here",
    title: "Title",
    publishing_year: "Publishing year",
    submit: "Submit",
    delete: "Delete",
    delete_movie: "Delete Movie - ",
    delete_message:
      "Are you sure you want to delete this movie? Your movie will be permanently removed. This action cannot be undone.",
    success: "Success!",
    add_success_message: "Movie added successfully",
    update_success_message: "Movie updated successfully",
    delete_sucess_message: "Movie deleted successfully",
    empty_movie_list: "Your movie list is empty",
    add_new_movie: "Add a new movie",
    previous: "Prev",
    next: "Next",
    required_field: "Required field",
    language: "Language",
    choose_language: "Choose language",
    english: "English",
    spanish: "Spanish",
    hindi: "Hindi",
  },
  sp: {
    sign_in: "Acceso",
    username: "nombre de usuario",
    password: "contraseña",
    remember_me: "Acuérdate de mí",
    login: "Iniciar la sesión",
    my_movies: "Mis peliculas",
    logout: "Cerrar sesión",
    update_movie: "Actualizar película",
    cancel: "Cancelar",
    update: "Actualizar",
    create_new_movie: "Crear una nueva película",
    drop_image_here: "Deja una imagen aquí",
    title: "Título",
    publishing_year: "Año de publicación",
    submit: "Entregar",
    delete: "Borrar",
    delete_movie: "Eliminar película - ",
    delete_message:
      "¿Estás seguro de que deseas eliminar esta película? Su película será eliminada permanentemente. Esta acción no se puede deshacer.",
    success: "Éxito!",
    add_success_message: "Película agregada exitosamente",
    update_success_message: "Película actualizada exitosamente",
    delete_sucess_message: "Película eliminada exitosamente",
    empty_movie_list: "Tu lista de películas está vacía",
    add_new_movie: "Agregar una nueva película",
    previous: "Anterior",
    next: "Próxima",
    required_field: "Campo requerido",
    language: "Idioma",
    choose_language: "Elige lengua",
    english: "Inglesa",
    spanish: "Española",
    hindi: "Hindi",
  },
  hi: {
    sign_in: "साइन इन",
    username: "उपयोगकर्ता नाम",
    password: "पासवर्ड",
    remember_me: "याद रखें",
    login: "लॉग इन",
    my_movies: "मेरी फिल्में",
    logout: "लॉग आउट",
    update_movie: "मूवी अपडेट करें",
    cancel: "रद्द करें",
    update: "अपडेट करें",
    create_new_movie: "एक नई फिल्म बनाएं",
    drop_image_here: "यहां एक छवि छोड़ें",
    title: "शीर्षक",
    publishing_year: "प्रकाशन वर्ष",
    submit: "निवेदित करें",
    delete: "हटाएं",
    delete_movie: "मूवी को हटाएं - ",
    delete_message:
      "क्या आप वाकई इस फ़िल्म को हटाना चाहते हैं? आपकी फ़िल्म को स्थायी रूप से हटा दिया जाएगा. इस एक्शन को पूर्ववत नहीं किया जा सकता है।",
    success: "सफलता!",
    add_success_message: "मूवी सफलतापूर्वक जोड़ दी गई है",
    update_success_message: "मूवी सफलतापूर्वक अपडेट कर दी गई है",
    delete_sucess_message: "मूवी सफलतापूर्वक हटा दी गई है",
    empty_movie_list: "आपकी फ़िल्म सूची खाली है",
    add_new_movie: "सूची में एक नई फिल्म जोड़ें",
    previous: "पिछला",
    next: "अगला",
    required_field: "आवश्यक फ़ील्ड",
    language: "भाषा",
    choose_language: "भाषा चुनें",
    english: "अंग्रेज़ी",
    spanish: "स्पैनिश",
    hindi: "हिंदी",
  },
};
