import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import alert from "../alert/alertReducer";
import login from "../login/loginReducer";
import movie from "../movie/movieReducer";
import language from "../language/languageReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [""],
};

export const rootReducer = (routerReducer) =>
  persistReducer(
    persistConfig,
    combineReducers({
      router: routerReducer,
      alert,
      login,
      movie,
      language,
    })
  );
