import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  ConfirmModal,
  Header,
  MovieCard,
  Pagination,
  Skeleton,
} from "../../../common";
import { Link } from "react-router-dom";
import { CREATE, LIST } from "../../../../navigation/routes";
import { deleteMovieById } from "../../../../store/movie/movieActions";

const MoviesListView = ({ onChangePage }) => {
  const dispatch = useDispatch();

  const { movieListResponse, movieListPending, languagePreference } =
    useSelector((state) => ({
      movieListResponse: state.movie.movieListResponse,
      movieListPending: state.movie.movieListPending,
      languagePreference: state.language.languagePreference,
    }));

  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [movieListResponse]);

  const onClose = () => {
    setOpen(false);
    setData(null);
  };

  const handleDelete = () => {
    dispatch(
      deleteMovieById(data._id, () => {
        onClose();
        window.scrollTo(0, 0);
      })
    );
  };

  return (
    <div className="dashboard h-full text-white">
      <Header title={languagePreference.my_movies} showAdd />

      <div className="list">
        {movieListPending ? (
          <Skeleton />
        ) : (
          <div>
            {movieListResponse?.docs.length ? (
              <>
                <section className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-4 2xl:gap-8">
                  {movieListResponse?.docs.map((data, index) => (
                    <MovieCard
                      setOpen={setOpen}
                      key={data._id}
                      data={data}
                      index={index}
                      setData={setData}
                    />
                  ))}
                </section>
                <Pagination
                  movieListResponse={movieListResponse}
                  onChangePage={onChangePage}
                />
              </>
            ) : (
              <div className="pt-10 flex-center flex-col">
                <div className="title-medium">
                  {languagePreference.empty_movie_list}
                </div>
                <Link
                  to={`${LIST}${CREATE}`}
                  className="py-[15px] px-[28px] bg-primary font-semibold rounded-[10px] text-white "
                >
                  {languagePreference.add_new_movie}
                </Link>
              </div>
            )}
          </div>
        )}
      </div>

      <ConfirmModal
        open={open}
        onClose={onClose}
        setOpen={setOpen}
        handleDelete={handleDelete}
        data={data}
      />
    </div>
  );
};

export default MoviesListView;
