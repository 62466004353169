import { showError } from "../../helpers/errorHandler";
import { setNotification } from "../../helpers/notificationHandler";
import { LIST } from "../../navigation/routes";
import Api from "../../services/api";
import * as commonUtility from "../../services/commonUtility";
import { globals } from "../globals";

export const actionTypes = {
  MOVIE_LIST_PENDING: "MOVIE_LIST_PENDING",
  MOVIE_LIST_SUCCESS: "MOVIE_LIST_SUCCESS",
  MOVIE_LIST_ERROR: "MOVIE_LIST_ERROR",

  MOVIE_CREATE_PENDING: "MOVIE_CREATE_PENDING",
  MOVIE_CREATE_SUCCESS: "MOVIE_CREATE_SUCCESS",
  MOVIE_CREATE_ERROR: "MOVIE_CREATE_ERROR",

  MOVIE_UPDATE_PENDING: "MOVIE_UPDATE_PENDING",
  MOVIE_UPDATE_SUCCESS: "MOVIE_UPDATE_SUCCESS",
  MOVIE_UPDATE_ERROR: "MOVIE_UPDATE_ERROR",

  MOVIE_DELETE_PENDING: "MOVIE_DELETE_PENDING",
  MOVIE_DELETE_SUCCESS: "MOVIE_DELETE_SUCCESS",
  MOVIE_DELETE_ERROR: "MOVIE_DELETE_ERROR",

  MOVIE_PENDING: "MOVIE_PENDING",
  MOVIE_SUCCESS: "MOVIE_SUCCESS",
  MOVIE_ERROR: "MOVIE_ERROR",
};

export function getMoviesList(page = 1) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.MOVIE_LIST_PENDING,
      payload: {
        movieListPending: true,
        movieListSuccess: false,
        movieListError: null,
        movieListResponse: null,
      },
    });

    Api.post(`movie/list?page=${page}&limit=${8}`)
      .then((response) => {
        try {
          const { status } = response;
          if (status !== 200) {
            throw response;
          } else {
            dispatch({
              type: actionTypes.MOVIE_LIST_SUCCESS,
              payload: {
                movieListPending: false,
                movieListSuccess: true,
                movieListError: null,
                movieListResponse: response.data,
              },
            });
          }
        } catch (err) {
          console.log({ err });
        }
      })
      .catch((err) => {
        console.log({ err });
        var message = "";
        message = commonUtility.sendError(err);
        dispatch({
          type: actionTypes.MOVIE_LIST_ERROR,
          payload: {
            movieListPending: false,
            movieListSuccess: false,
            movieListError: message,
            movieListResponse: null,
          },
        });

        showError(err, (isServerError) => {
          if (!isServerError) {
            setNotification({
              title: "Error!",
              type: "danger",
              msg: message,
            });
          }
        });
      });
  };
}

export function createMovie(fields) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.MOVIE_CREATE_PENDING,
      payload: {
        movieCreatePending: true,
        movieCreateSuccess: false,
        movieCreateError: null,
        movieCreateResponse: null,
      },
    });

    const { title, year, file } = fields;

    // console.log({ file });

    const formdata = new FormData();
    if (file) {
      formdata.append("movieFile", file);
    }
    formdata.append("year", year);
    formdata.append("title", title);

    Api.post(`movie/create`, formdata)
      .then((response) => {
        try {
          const { status } = response;
          if (status !== 200) {
            throw response;
          } else {
            dispatch({
              type: actionTypes.MOVIE_CREATE_SUCCESS,
              payload: {
                movieCreatePending: false,
                movieCreateSuccess: true,
                movieCreateError: null,
                movieCreateResponse: response.data,
              },
            });

            setNotification({
              title: "Success!",
              type: "success",
              msg: response.message,
            });

            globals.history.push(LIST);
          }
        } catch (err) {
          console.log({ err });
        }
      })
      .catch((err) => {
        console.log({ err });
        var message = "";
        message = commonUtility.sendError(err);
        dispatch({
          type: actionTypes.MOVIE_CREATE_ERROR,
          payload: {
            movieCreatePending: false,
            movieCreateSuccess: false,
            movieCreateError: message,
            movieCreateResponse: null,
          },
        });

        showError(err, (isServerError) => {
          if (!isServerError) {
            setNotification({
              title: "Error!",
              type: "danger",
              msg: message,
            });
          }
        });
      });
  };
}

export function updateMovie(fields, id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.MOVIE_UPDATE_PENDING,
      payload: {
        movieUpdatePending: true,
        movieUpdateSuccess: false,
        movieUpdateError: null,
        movieUpdateResponse: null,
      },
    });

    const { title, year, file } = fields;

    // console.log({ file });

    const formdata = new FormData();
    if (file) {
      formdata.append("movieFile", file);
    }
    formdata.append("year", year);
    formdata.append("title", title);

    Api.put(`movie/${id}`, formdata)
      .then((response) => {
        try {
          const { status } = response;
          if (status !== 200) {
            throw response;
          } else {
            dispatch({
              type: actionTypes.MOVIE_UPDATE_SUCCESS,
              payload: {
                movieUpdatePending: false,
                movieUpdateSuccess: true,
                movieUpdateError: null,
                movieUpdateResponse: response.data,
              },
            });

            setNotification({
              title: "Success!",
              type: "success",
              msg: response.message,
            });

            globals.history.push(LIST);
          }
        } catch (err) {
          console.log({ err });
        }
      })
      .catch((err) => {
        console.log({ err });
        var message = "";
        message = commonUtility.sendError(err);
        dispatch({
          type: actionTypes.MOVIE_UPDATE_ERROR,
          payload: {
            movieUpdatePending: false,
            movieUpdateSuccess: false,
            movieUpdateError: message,
            movieUpdateResponse: null,
          },
        });

        showError(err, (isServerError) => {
          if (!isServerError) {
            setNotification({
              title: "Error!",
              type: "danger",
              msg: message,
            });
          }
        });
      });
  };
}

export function getMovieById(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.MOVIE_PENDING,
      payload: {
        moviePending: true,
        movieSuccess: false,
        movieError: null,
        movieResponse: null,
      },
    });

    Api.get(`movie/${id}`)
      .then((response) => {
        try {
          const { status } = response;
          if (status !== 200) {
            throw response;
          } else {
            dispatch({
              type: actionTypes.MOVIE_SUCCESS,
              payload: {
                moviePending: false,
                movieSuccess: true,
                movieError: null,
                movieResponse: response.data,
              },
            });
          }
        } catch (err) {
          console.log({ err });
        }
      })
      .catch((err) => {
        console.log({ err });
        var message = "";
        message = commonUtility.sendError(err);
        dispatch({
          type: actionTypes.MOVIE_ERROR,
          payload: {
            moviePending: false,
            movieSuccess: false,
            movieError: message,
            movieResponse: null,
          },
        });

        showError(err, (isServerError) => {
          if (!isServerError) {
            setNotification({
              title: "Error!",
              type: "danger",
              msg: message,
            });
          }
        });
      });
  };
}

export function deleteMovieById(id, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.MOVIE_DELETE_PENDING,
      payload: {
        movieDeletePending: true,
        movieDeleteSuccess: false,
        movieDeleteError: null,
        movieDeleteResponse: null,
      },
    });

    Api.delete(`movie/${id}`)
      .then((response) => {
        try {
          const { status } = response;
          if (status !== 200) {
            throw response;
          } else {
            dispatch({
              type: actionTypes.MOVIE_DELETE_SUCCESS,
              payload: {
                movieDeletePending: false,
                movieDeleteSuccess: true,
                movieDeleteError: null,
                movieDeleteResponse: response.data,
              },
            });
            setNotification({
              title: "Success!",
              type: "success",
              msg: response.message,
            });
            callback();
            dispatch(getMoviesList(1));
          }
        } catch (err) {
          console.log({ err });
        }
      })
      .catch((err) => {
        console.log({ err });
        var message = "";
        message = commonUtility.sendError(err);
        dispatch({
          type: actionTypes.MOVIE_DELETE_ERROR,
          payload: {
            movieDeletePending: false,
            movieDeleteSuccess: false,
            movieDeleteError: message,
            movieDeleteResponse: null,
          },
        });

        showError(err, (isServerError) => {
          if (!isServerError) {
            setNotification({
              title: "Error!",
              type: "danger",
              msg: message,
            });
          }
        });
      });
  };
}
