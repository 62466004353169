import { languageList } from "../../constants/localizations";
import { actionTypes } from "./languageActions";

const { SET_LANGUAGE_PREFERENCE } = actionTypes;

const initialState = {
  languagePreference:
    languageList[JSON.parse(localStorage.getItem("chosenLanguageKey")) || "en"],
};

export default function language(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_LANGUAGE_PREFERENCE:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
