import { USERS } from "../../constants/appConstant";
import { showError } from "../../helpers/errorHandler";
import { setNotification } from "../../helpers/notificationHandler";
import { LIST, LOGIN } from "../../navigation/routes";
import Api from "../../services/api";
import * as commonUtility from "../../services/commonUtility";
import { encrypt_pwd } from "../../services/pwdProtect";
import { globals } from "../globals";

export const actionTypes = {
  LOGIN_PENDING: "LOGIN_PENDING",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  LOGIN_REMEMBER: "LOGIN_REMEMBER",
  RESET_LOGIN_REMEMBER: "RESET_LOGIN_REMEMBER",
  LOGOUT: "LOGOUT",
};

export function login(data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LOGIN_PENDING,
      payload: {
        loginPending: true,
        loginSuccess: false,
        loginError: null,
        loginResponse: null,
      },
    });

    const { remember, email, password } = data;

    const reqData = {
      userName: email,
      password: password,
    };

    Api.post(`auth/signin`, reqData)
      .then((response) => {
        try {
          const { status } = response;
          if (status !== 200) {
            throw response;
          } else {
            localStorage.setItem(
              "token",
              JSON.stringify(response.data.accessToken)
            );
            localStorage.setItem("userData", JSON.stringify(response.data));
            dispatch({
              type: actionTypes.LOGIN_SUCCESS,
              payload: {
                loginPending: false,
                loginSuccess: true,
                loginError: null,
                loginResponse: response,
                accessToken: response.data.accessToken,
                userData: response,
              },
            });

            if (remember == true) {
              var pwd = encrypt_pwd(password);
              dispatch(setLoginRemember(email, pwd));
            } else {
              dispatch(resetLoginRemember());
              localStorage.removeItem("loginRemember");
            }

            globals.history.push(LIST);
          }
        } catch (err) {
          console.log({ err });
        }
      })
      .catch((err) => {
        console.log({ err });
        var message = "";
        message = commonUtility.sendError(err);
        dispatch({
          type: actionTypes.LOGIN_ERROR,
          payload: {
            loginPending: false,
            loginSuccess: false,
            loginError: message,
            loginResponse: null,
          },
        });

        showError(err, (isServerError) => {
          if (!isServerError) {
            setNotification({
              title: "Error!",
              type: "danger",
              msg: message,
            });
          }
        });
      });
  };
}

export function setLoginRemember(name, pwd) {
  return (dispatch) => {
    const data = {
      email: name,
      password: pwd,
      remember: true,
    };
    localStorage.setItem("loginRemember", JSON.stringify(data));
    dispatch({
      type: actionTypes.LOGIN_REMEMBER,
      payload: {
        loginRemember: true,
        loginRememberName: name,
        loginRememberPwd: pwd,
      },
    });
  };
}

export function resetLoginRemember() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_LOGIN_REMEMBER,
      payload: {
        loginRemember: false,
        loginRememberName: null,
        loginRememberPwd: null,
      },
    });
  };
}
export function logout() {
  return (dispatch) => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    dispatch({
      type: actionTypes.LOGOUT,
      payload: {
        loginPending: false,
        loginSuccess: true,
        loginError: null,
        loginResponse: null,
        accessToken: null,
        userData: null,
      },
    });

    globals.history.push(LOGIN);
  };
}
