import React from "react";
import { wave, waveMob } from "../../../constants/imageConstant";

const Wave = () => {
  return (
    <div className="wave w-full">
      <img src={wave} alt="wave" className="w-full h-auto hidden md:block" />
      <img src={waveMob} alt="wave" className="w-full h-auto block md:hidden" />
    </div>
  );
};

export default Wave;
