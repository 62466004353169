import { Globe } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useDispatch } from "react-redux";
import { setLanguagePreference } from "../../../store/language/languageActions";
import {
  languageEnglish,
  languageHindi,
  languageSpanish,
} from "../../../constants/appConstant";

const ChooseLanguage = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const menu = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);

    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  }, [open]);

  const closeOpenMenus = (e) => {
    if (menu.current && open && !menu.current.contains(e.target)) {
      setOpen(false);
    }
  };

  return (
    <div className="relative text-[14px] leading-6">
      <Globe
        className="cursor-pointer focus:outline-none"
        data-tooltip-id="choose_language"
        onClick={() => {
          setOpen(!open);
        }}
      />
      <ReactTooltip
        id="choose_language1"
        variant="dark"
        place="left"
        content="Choose language"
      />
      {open && (
        <ul
          ref={menu}
          className="absolute left-[-25px] p-2 mt-3 shadow menu dropdown-content z-[1]  rounded bg-slate-100 rounded-box w-20"
        >
          <li
            onClick={() => {
              dispatch(setLanguagePreference(languageEnglish));
              setOpen(!open);
            }}
            className="text-center mb-1 cursor-pointer"
          >
            <a className="!text-paginationBg font-normal text-center text-[14px]">
              English
            </a>
          </li>
          <li
            onClick={() => {
              dispatch(setLanguagePreference(languageSpanish));
              setOpen(!open);
            }}
            className="text-center mb-1 cursor-pointer"
          >
            <a className="!text-paginationBg font-normal text-center text-[14px]">
              Spanish
            </a>
          </li>
          <li
            onClick={() => {
              dispatch(setLanguagePreference(languageHindi));
              setOpen(!open);
            }}
            className="text-center cursor-pointer"
          >
            <a className="!text-paginationBg font-normal text-center text-[14px]">
              Hindi
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export default ChooseLanguage;
