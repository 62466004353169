import React, {
  useEffect,
  createContext,
  useContext,
  useState,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import * as validator from "../../../../helpers/validator";
import { trimFieldsData } from "../../../../utils";
import MoviesUpdateView from "./MoviesUpdateView";
import {
  getMovieById,
  updateMovie,
} from "../../../../store/movie/movieActions";

const UpdateMovieContext = createContext({});
export const useUpdateMovie = () => useContext(UpdateMovieContext);

const initFields = {
  title: "",
  year: "",
  file: null,
};

const MoviesUpdateContainer = () => {
  const dispatch = useDispatch();
  let { id } = useParams();

  const { movieUpdatePending, movieResponse } = useSelector((state) => ({
    movieUpdatePending: state.movie.movieUpdatePending,
    movieResponse: state.movie.movieResponse,
  }));

  const [fields, setFields] = useState(initFields);
  const [errors, setErrors] = useState({});
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    dispatch(getMovieById(id));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (movieResponse) {
      setFields({
        title: movieResponse.title,
        year: movieResponse.year,
        file: null,
      });
      setImageUrl(movieResponse.imageUrl);
    }
  }, [movieResponse]);

  const handleChange = (field) => (e) => {
    let value;
    const isDate = e instanceof Date;
    const isTextField = e?.target;
    const isNull = e === null;
    if (isDate) {
      value = e;
    } else if (isNull) {
      value = e;
    } else if (isTextField) {
      value = e.target.value;
    } else {
      value = e;
    }

    setFields({
      ...fields,
      [field]: value,
    });
  };

  const handleSubmit = () => {
    let required = ["title", "year"];
    if (!imageUrl) {
      required.push("file");
    }
    const validatorFields = {
      required: required,
    };

    const { errors } = validator.validate(
      validatorFields,
      trimFieldsData(fields)
    );

    setErrors(errors);

    if (!Object.keys(errors).length) {
      dispatch(updateMovie(fields, id));
    }
  };

  return (
    <UpdateMovieContext.Provider
      value={{
        fields,
        errors,
        imageUrl,
        setImageUrl,

        onChange: handleChange,
        loading: movieUpdatePending,
        onSubmit: handleSubmit,
        movieResponse,
      }}
    >
      <MoviesUpdateView />
    </UpdateMovieContext.Provider>
  );
};

export default MoviesUpdateContainer;
