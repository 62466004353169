import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import auth from "./auth";
import { LOGIN } from "../../../navigation/routes";

const ProtectedRoute = ({ children, location }) => {
  const history = useNavigate();

  var str = window.location;
  var url = new URL(str);

  useEffect(() => {
    if (!auth.isAuthenticated()) {
      history(LOGIN, {
        state: {
          from: location,
          original_path: url.pathname,
        },
      });
    }
  }, [auth]);

  return !auth.isAuthenticated() ? null : children;
};

export default ProtectedRoute;
