import React from "react";
import { ArrowLeft } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

import { CREATE, LIST } from "../../../navigation/routes";
import { addIcon, logoutIcon } from "../../../constants/imageConstant";
import { logout } from "../../../store/login/loginActions";
import ChooseLanguage from "../ChooseLanguage/ChooseLanguage";

const Header = ({
  title = "",
  showAdd = false,
  showBack = false,
  showLogout = true,
}) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { languagePreference } = useSelector((state) => ({
    languagePreference: state.language.languagePreference,
  }));

  return (
    <div className="header flex justify-between mb-[10px] md:mb-[30px]">
      <div className={`title flex items-start ${showBack && "gap-4"}`}>
        {showBack && (
          <ArrowLeft
            className="mt-2 lg:mt-4 cursor-pointer"
            size={28}
            onClick={() => navigate(-1)}
          />
        )}
        <div className="title-medium">{title}</div>
        {showAdd && (
          <>
            <img
              className="mt-[7px] lg:mt-[18px] ml-2 cursor-pointer"
              src={addIcon}
              alt="add"
              width={30}
              height={30}
              onClick={() => {
                navigate(`${LIST}${CREATE}`);
              }}
              data-tooltip-id="add"
            />
            <ReactTooltip
              id="add"
              variant="dark"
              place="right"
              content="Add new movie"
            />
          </>
        )}
      </div>
      {showLogout && (
        <div className="mt-3 flex items-top">
          <ChooseLanguage />
          {/* mt-3 lg:mt-6  */}
          <div
            className="logout font-semibold flex items-start 
            lg:mt-[2px]
           lg:ml-5
           ml-3
            cursor-pointer"
            onClick={() => {
              dispatch(logout());
            }}
          >
            <div className="title hidden lg:block">
              {languagePreference.logout}
            </div>
            <img
              className="ml-2"
              src={logoutIcon}
              alt="logout"
              width={25}
              height={25}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
