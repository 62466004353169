import { actionTypes } from "./loginActions";
import Auth from "../../services/auth";

const { LOGIN_PENDING, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT } = actionTypes;

const initialState = {
  loginPending: false,
  loginSuccess: false,
  loginError: null,
  loginResponse: null,

  accessToken: Auth.getTokenFromLocalStorage(),
  userData: Auth.getLoginUserFromLocalStorage(),
};

export default function login(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_PENDING:
    case LOGIN_SUCCESS:
    case LOGIN_ERROR:
      return Object.assign({}, state, payload);

    case LOGOUT:
      return Object.assign({}, state, payload);

    default:
      return state;
  }
}
