import axios from "axios";
import Auth from "./auth";
import { DOMAIN_API_URI, PATH_API_URI } from "./apiConstants";

export default class Api {
  static actionsStack = [];

  static methods = {
    GET: "get",
    POST: "post",
    PATCH: "patch",
    PUT: "put",
    DELETE: "delete",
  };

  static get initialStatus() {
    return {
      loading: false,
      loaded: false,
      fail: false,
    };
  }

  static get requestStatus() {
    return {
      loading: true,
      loaded: false,
      fail: false,
    };
  }

  static get successStatus() {
    return {
      loading: false,
      loaded: true,
      fail: false,
    };
  }

  static get failStatus() {
    return {
      loading: false,
      loaded: false,
      fail: true,
    };
  }

  static composeRouteUrl(route) {
    if (route.startsWith("http")) {
      return route;
    }
    return `${DOMAIN_API_URI}${PATH_API_URI}${route}`;
  }

  static get(route, params) {
    return Api.request(route, params, undefined, Api.methods.GET);
  }

  static put(route, data, params) {
    return Api.request(route, params, data, Api.methods.PUT);
  }

  static patch(route, params, data) {
    return Api.request(route, params, data, Api.methods.PATCH);
  }

  static post(route, data, appendHeaders, handleFormError = true) {
    return Api.request(
      route,
      undefined,
      data,
      Api.methods.POST,
      appendHeaders,
      handleFormError
    );
  }

  static delete(route, params) {
    return Api.request(route, params, undefined, Api.methods.DELETE);
  }

  static request(route, params, data, method, appendHeaders) {
    const url = Api.composeRouteUrl(route, params);

    let headers = {
      Accept: "application/json",
      // "Content-Type": "application/json; charset=utf-8",
      "X-Requested-With": "XMLHttpRequest",
    };
    const token = Auth.getTokenFromLocalStorage();

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    if (appendHeaders) {
      headers = { ...headers, ...appendHeaders };
    }
    return axios({
      method,
      url,
      headers,
      params,
      data,
    })
      .then((resp) => {
        return { ...resp.data, status: resp.status };
      })
      .catch((err) => {
        throw err.response;
      });
  }
}
