import CryptoJS from "crypto-js";
import { secret_key } from "../constants/env";

export function encrypt_pwd(pass) {
  let pwd = CryptoJS.AES.encrypt(JSON.stringify(pass), secret_key).toString();
  return pwd;
}

export function decrypt_pwd(pass) {
  var bytes = CryptoJS.AES.decrypt(pass, secret_key);
  var pwd = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  return pwd;
}
