import { globals } from "../store/globals";
import { actionTypes } from "../store/alert/alertActions";
import { logout } from "../store/login/loginActions";

export const showError = (err, callback) => {
  if (!err) {
    globals.store.dispatch({
      type: actionTypes.SHOW_ALERT,
      payload: {
        title: "Error",
        isOpen: true,
        msg: "Server Error Occured",
        alertType: "error",
      },
    });
    callback(true);
    return;
  }

  if (
    (err.data.rid == "e-auth-16" || err.data.rid == "e-auth-14") &&
    localStorage.getItem("token")
  ) {
    globals.store.dispatch(logout());
    callback(true);
  } else if (err.status > 500 && err.status < 599) {
    // console.log({ globals });
    globals.store.dispatch({
      type: actionTypes.SHOW_ALERT,
      payload: {
        title: "Error",
        isOpen: true,
        msg: "Server Error Occured",
        alertType: "error",
      },
    });
    callback(true);
  } else {
    callback(false);
  }
};
