import React, { useCallback } from "react";
import { useSelector } from "react-redux";

const Pagination = ({ movieListResponse, onChangePage }) => {
  const { page, totalPages, nextPage, prevPage } = movieListResponse;

  const { languagePreference } = useSelector((state) => ({
    languagePreference: state.language.languagePreference,
  }));

  // console.log({ movieListResponse });

  let pagesList = [];
  for (let i = 1; i <= totalPages; i++) {
    pagesList.push(i);
  }

  return (
    <div className="pagination pt-10 flex-center gap-2">
      <div
        onClick={() => {
          if (prevPage) {
            onChangePage(prevPage);
          }
        }}
        className={`prev text-base font-bold leading-6 ${
          prevPage ? "cursor-pointer" : "text-slate-400"
        } `}
      >
        {languagePreference.previous}
      </div>
      {pagesList.map((pageNumber) => {
        return (
          <div
            onClick={() => {
              if (page != pageNumber) {
                onChangePage(pageNumber);
              }
            }}
            key={pageNumber}
            className={`${
              page == pageNumber ? "bg-primary" : "bg-paginationBg"
            } prev text-base font-bold leading-6 px-3 py-1 rounded-md cursor-pointer`}
          >
            {pageNumber}
          </div>
        );
      })}
      <div
        onClick={() => {
          if (nextPage) {
            onChangePage(nextPage);
          }
        }}
        className={`prev text-base font-bold leading-6 ${
          nextPage ? "cursor-pointer" : "text-slate-400"
        } `}
      >
        {languagePreference.next}
      </div>
    </div>
  );
};

export default Pagination;
